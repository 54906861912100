import React from 'react';
import PageMeta from '../../../components/common/PageMeta';
import Banner from '../../../components/disclosure-infos/Banner';
import DetailWraper from '../../../components/disclosure-infos/Detail';
import Navbar from '../../../layout/Header/Navbar';
import Layout from '../../../layout/Layout';
import FooterOne from '../../../layout/Footer/FooterOne';
import Background from 'assets/img/action-bg.png'
import Qualification from 'assets/img/qualification.jpg'


const DisclosureInfosList = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <Banner />
      <DetailWraper>
        <div className="support-article-wrap">
          <h3 className=" mb-4 fw-bold text-center">资质信息</h3>

          <p className="text-muted text-center pt-0 pb-4">
            <span className="px-3">发布时间：2021年11月12日</span>{' '}
            <span className="px-3">来源：本站</span>
          </p>

          <section
            className="work-process ptb-20 rounded bg-dark text-white  mb-5"
            style={{
              background: `url(${Background}) no-repeat top center`,
              backgroundSize: 'cover',
            }}
          >
            <p className="p-5 text-center fs-7 lh-lg">
              遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，遵守社会道德风尚，倡导个人、企业社会责任，致力于心血管
              <br />
              疾病防御和普及知识，为社会医疗事业的发展添砖加瓦，促进社会公益事业的发展。
            </p>
          </section>

          <div className="article-detail">
              <img
                src={ Qualification }
                alt='client'
                className='img-fluid'
              />
          </div>
        </div>
      </DetailWraper>
      <FooterOne footerLight />
    </Layout>
  );
};

export default DisclosureInfosList;
