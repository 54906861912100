import { initUseAxios } from "helpers/api_helper"

import Routes from './routers/Routes';

import './assets/scss/style.scss';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';

//Modal Video
import 'react-modal-video/scss/modal-video.scss';


const App = () => {

  initUseAxios()

  return (
    <>
      <Routes />
    </>
  );
};

export default App;
