import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import AboutusHeader from '../../components/about-us/AboutusHeader';
import AboutusDetail from '../../components/about-us/AboutusDetail';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FooterOne from '../../layout/Footer/FooterOne';
import Background from 'assets/img/action-bg.png'

const Descrition = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <AboutusHeader  title="深圳亚心慈善基金会简介"  />
      <AboutusDetail>
        <div className="support-article-wrap">
          <h3 className=" mb-4 fw-bold text-center">深圳亚心慈善基金会简介</h3>

          <p className="text-muted text-center pt-0 pb-4">
            <span className="px-3">发布时间：2021年11月12日</span>{' '}
            <span className="px-3">来源：本站</span>
          </p>

          <section
            className="work-process ptb-20 rounded bg-dark text-white  mb-5"
            style={{
              background: `url(${Background}) no-repeat top center`,
              backgroundSize: 'cover',
            }}
          >
            <p className="p-5 text-center fs-7  lh-lg">
              遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，遵守社会道德风尚，倡导个人、企业社会责任，致力于心血管
              <br />
              疾病防御和普及知识，为社会医疗事业的发展添砖加瓦，促进社会公益事业的发展。
            </p>
          </section>

          <div className="article-detail">
            <p>
              深圳亚心慈善基金会（以下简称亚心慈善基金会）简介，基金会于 2021 年
              4 月 13 日正式在深圳市民政局登记成为全国非公募基金会。
            </p>

            <p>
              亚心慈善基金会，推动心血管内科特别是心律失常领域的基础与临床研究、科技成果转化、学术交流合作、疾病科普、健康公益服务等。
            </p>

            <h3>基金会宗指</h3>

            <p>
              遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，遵守社会道德风尚，倡导个人、企业社会责任，致力于心血管疾病防御和普及知识，为社会医疗事业的发展添砖加瓦，促进社会公益事业的发展。
            </p>

            <h3>机构业务范围</h3>

            <p>
              （一）开展各种形式的募捐活动，接受政府资助，接受自然人、法人及其他组织的合法捐赠；
            </p>

            <p>
              （二）资助或参与心血管内科特别是心律失常相关疾病的学术研究、成果转化、学术会议、学术交流、继续教育、科普知识传播、公共卫生等项目；
            </p>

            <p>
              （三）奖励在区域及国内外心血管内科尤其是心律失常领域内做出重要贡献的个人或团队；
            </p>

            <p>
              （四）依照相关规定，合法编辑、出版有关书籍、刊物或其他媒体材料；
            </p>

            <p>（五）开展符合国家法律法规及本基金会宗旨的其它有关活动。</p>

            <p>
              未来，基金会将在延续以往公益工作的基础上，积极建设自身品牌，开拓新时代下更具持续影响力的公益道路。
            </p>
          </div>
        </div>
      </AboutusDetail>
      <FooterOne footerLight />
    </Layout>
  );
};

export default Descrition;
