import axios, { Cancel } from "axios"
import { configure } from "axios-hooks"
import { toast } from 'react-toastify';
import { isArray } from "lodash-es";

import Cookies from 'universal-cookie';

const cookies = new Cookies();
const two_weeks = 1209600;

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL
const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use(
  (reqConfig) => {
    let token = localStorage.getItem("accessToken");

    if (token && ! reqConfig.url.includes('login')) {
      reqConfig.headers["Authorization"] = `Bearer ${token}`;
    }
    return reqConfig;
  },
  (err) => Promise.reject(err)
);


let isRefreshing = false;
let requests = [];

axiosApi.interceptors.response.use(
  response => response,
  error => {

    // if Cancel the instance
    if (error instanceof Cancel) {
      return Promise.reject(error)
    }

    // if Network error
    if (!error.response) {

      // add axios-mock-adapter ignore
      if ( !isArray( error ) ) {
        toast.error("网络出错!")
      }
      return Promise.reject(error);

    }

    if (error.response.status === 500) {

      toast.error( error.response.data.message )
      return Promise.reject(error);

    }

    if (
      error.response.status === 401 &&
      error.response.data.message !== "Token has expired" &&
      !window.location.pathname.includes("login")
    ) {
      window.location.href = "/auth/login"
    }

    let { config } = error.response

    if (
      error.response.status === 401 &&
      error.response.data.message === "Token has expired"
    ) {
      if (!isRefreshing) {
        isRefreshing = true
        return refreshToken()
          .then(res => {
            const { authorization: token } = res.headers
            setToken(token.replace(/Bearer /, ""))
            config.headers["Authorization"] = token
            requests.forEach(cb => cb(token))
            requests = []
            return axiosApi(config)
          })
          .catch(err => {
            console.error("referrh token error =>", err)
            return Promise.reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      } else {
        return new Promise(resolve => {
          requests.push(token => {
            config.headers["Authorization"] = `Bearer ${token}`
            resolve(axiosApi(config))
          })
        })
      }
    }
    return Promise.reject(error)
  }
)


// set token
const setToken = token => {
  axiosApi.defaults.headers["Authorization"] = `Bearer ${token}`;

  if ( cookies.get('remeber_me') ) {
    cookies.set('access_token', token, { path: '/', maxAge : two_weeks });
    cookies.set('remeber_me', cookies.get('remeber_me'), { path: '/', maxAge : two_weeks });
  } else {
    cookies.set('access_token', token, { path: '/' });
  }

};


// refresh token
const refreshToken = () => {
  return axiosApi.get("/api/auth/refresh").then((res) => {
    return res;
  });
};

export async function get(url, config = {}) {
  return await axiosApi.get(url, (config = {})).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return await axiosApi
    .post(url, data, (config = {}))
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return await axiosApi
    .put(url, data, (config = {}))
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, (config = {}))
    .then(response => response.data)
}

export function initUseAxios() {
  configure({ axios: axiosApi })
}

export { axiosApi }
