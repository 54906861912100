import React from 'react';
import { Link } from 'react-router-dom';

import LogoColor from 'assets/img/logo-color.png'


const OffCanvasMenu = () => {
  return (
    <>
      <div
        className='offcanvas offcanvas-end'
        tabIndex='-1'
        id='offcanvasWithBackdrop'
      >
        <div className='offcanvas-header d-flex align-items-center mt-0 pt-2 pb-0 '>
          <Link
            to='/'
            className='d-flex align-items-center mb-md-0 text-decoration-none'
          >
            <img
              src={LogoColor}
              height={44}
              alt='logo'
              className='ps-2  mt-4'
            />
          </Link>
          <button
            type='button'
            className='close-btn text-danger'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          >
            <i className='far fa-close'></i>
          </button>
        </div>

        <hr />

        <div className='offcanvas-body'>
          <ul className='nav col-12 col-md-auto justify-content-center main-menu'>
            <li className="nav-item dropdown">
              <Link
                className="nav-link"
                to={process.env.PUBLIC_URL + '/'}
              >
                首页
              </Link>
            </li>
            <li>
              <Link
                to={process.env.PUBLIC_URL + '/about-us'}
                className="nav-link"
              >
                基金会介绍
              </Link>
            </li>

            <li>
              <Link
                to={process.env.PUBLIC_URL + '/news/media'}
                className="nav-link"
              >
                新闻动态
              </Link>
            </li>


            <li className="nav-item dropdown">
              <Link
                className="nav-link"
                to={process.env.PUBLIC_URL + '/projects'}
              >
                公益项目介绍
              </Link>
            </li>

            <li>
              <Link
                to={process.env.PUBLIC_URL + '/policies'}
                className="nav-link"
              >
                政策法规
              </Link>
            </li>

            <li>
              <Link
                to={process.env.PUBLIC_URL + '/disclosure-infos'}
                className="nav-link"
              >
                信息公示
              </Link>
            </li>


            <li>
              <Link
                to={process.env.PUBLIC_URL + '/contact-us'}
                className="nav-link"
              >
                联系我们
              </Link>
            </li>

            <li>
              <Link
                to={process.env.PUBLIC_URL + '/partners'}
                className="nav-link"
              >
                合作伙伴
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default OffCanvasMenu;
