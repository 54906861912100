import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import TrainingHeader from '../../components/header/TrainingHeader';
import NewsDetailWraper from '../../components/news/NewsDetail';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FooterOne from '../../layout/Footer/FooterOne';
import { useParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import Background from 'assets/img/action-bg.png';

const TrainingsDetail = () => {
  const param = useParams();

  const [{ data, loading, error }, fetchTableData] = useAxios(
    `/api/articles/${param.id}`
  );

  return (
    <Layout>
      <PageMeta />
      <Navbar />
      {data && (
        <>
          <TrainingHeader data={data} />
          <NewsDetailWraper>
            <div className="support-article-wrap">
            <h3 className=" mb-4 fw-bold text-center">{data.title}</h3>

              <p className="text-muted text-center pt-0 pb-4">
                <span className="px-3">发布时间：{data.published_at}</span>{' '}
                <span className="px-3">来源：本站</span>
              </p>

              <section
                className="work-process ptb-20 rounded bg-dark text-white  mb-5"
                style={{
                  background: `url(${Background}) no-repeat top center`,
                  backgroundSize: 'cover',
                }}
              >
                <p className="p-5 text-center fs-7  lh-lg">
                  遵守宪法、法律、法规和国家政策，践行社会主义核心价值观，遵守社会道德风尚，倡导个人、企业社会责任，致力于心血管
                  <br />
                  疾病防御和普及知识，为社会医疗事业的发展添砖加瓦，促进社会公益事业的发展。
                </p>
              </section>

              <div
                className="article-detail"
                dangerouslySetInnerHTML={{ __html: data.body }}
              />
            </div>
          </NewsDetailWraper>
        </>
      )}

      <FooterOne footerLight />
    </Layout>
  );
};

export default TrainingsDetail;
