import React from 'react';
import { NavLink } from 'react-router-dom';

const ProjectsDetail = ({ children }) => {
  return (
    <>
      <section className="support-content ptb-60">
      <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-3 col-md-4 d-block d-md-block d-lg-block ">
              <div className="support-article-sidebar sticky-sidebar mb-5 ">
                <div
                  className="nav flex-column nav-pills w-100 support-article-tab bg-light pt-3 pb-5 px-3 bg-primary rounded bg-heart"
                  id="v-pills-support"
                  aria-orientation="vertical"
                >
                  <NavLink
                    className="nav-link text-white"
                    to={process.env.PUBLIC_URL + '/news/media'}
                  >
                    媒体声音
                  </NavLink>

                  <NavLink
                    className="nav-link text-white "
                    to={process.env.PUBLIC_URL + '/news/self'}
                  >
                    基金会项目动态
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-8">{children}</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectsDetail;
