import React from 'react';

const HeroTitle = ({ subtitle, title, desc }) => {
  return (
    <>
      {subtitle ? <h5 className='text-white'>{subtitle}</h5> : ''}
      <h1 className='fw-bold display-5 text-white py-5' data-aos='fade-up'>
        {title}
      </h1>
      <p className="pt-1 pb-5" data-aos='fade-up' data-aos-delay='50' dangerouslySetInnerHTML={{__html: desc }}>
      </p>
    </>
  );
};

export default HeroTitle;
