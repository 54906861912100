import React from 'react';

const SectionTitle = ({ subtitle, dark, title, centerAlign, description }) => {
  return (
    <>
      {subtitle ? (
        <div
          className={`${
            centerAlign ? 'section-heading text-center' : 'section-heading'
          }`}
          data-aos='fade-up'
        >
          <h4 className={`h5 ${dark ? 'text-warning' : 'text-primary'}`}>
            {subtitle}
          </h4>
          <div className="d-flex pb-3 justify-content-center">
            <h3 className=" title-left-border ps-3">{title}</h3>
          </div>
          <p dangerouslySetInnerHTML={{__html : description}}></p>
        </div>
      ) : (
        <div
          className={`${
            centerAlign ? 'section-heading text-center' : 'section-heading'
          }`}
          data-aos='fade-up'
        >
          <div className="d-flex pb-3 justify-content-center">
            <h3 className=" title-left-border ps-3">{title}</h3>
          </div>
          <p dangerouslySetInnerHTML={{__html : description}}></p>
        </div>
      )}
    </>
  );
};

export default SectionTitle;
